import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    TextField,
    Typography,
    Autocomplete
} from "@mui/material";
import {
    LocalizationProvider,
    DateTimePicker
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AssessmentRender from "../AssessmentRender";
import ErrorEvent from "./ErrorEvent";
// import { getJrNumberListFromCandidate } from "../../lib/db_candidate";
import { addEvent, getJrNumberDetails } from "../../lib/db_event";
import apiService from "../../lib/apiService";

function AddEvent({ isDialogOpen, handleCloseDialog, allEvent, onUpdate}) {
    const autoCompleteRef = useRef()
    const [isErrDialogOpen, setIsErrDialogOpen] = useState(false)
    const [formPayload, setFormPayload] = useState({
        name: '',
        jrNumber: '',
        aoc_company: '',
        aoc: '',
        venue: '',
        companyCountry: '',
        dateTime:'',
        date: '',
        assessment: []
    })
    const [assessmentList, setAssessmentList] = useState([
        { name: "Measurements", checked: false },
        { name: "Document Check", checked: false },
        { name: "Catwalk", checked: false },
        { name: "English Examinations", checked: false },
        { name: "Group Dynamics", checked: false },
        { name: "1:1 Interview", checked: false },
    ]);
    const formErrorObj = { error: false, msg: '' };
    const [formError, setFormError] = useState({
        name: formErrorObj,
        jrNumber: formErrorObj,
        companyCountry: formErrorObj,
        aoc_company: formErrorObj,
        aoc: formErrorObj,
        venue: formErrorObj,
        dateTime: formErrorObj,
        time: formErrorObj,
        assessment: formErrorObj,
    })
    const [errorSave, setErrorSave] = useState({
        type: 'Saving',
        msg: '',
    })
    const [allJrNumber, setAllJrNumber] = useState([])
    const [listCountry, setListCountry] = useState([])

    useEffect(() => {
        if (errorSave.msg.length > 0) {
            setIsErrDialogOpen(true)
        }
    }, [errorSave.msg.length])

    useEffect(()=>{
        const getListJrNumber  = async () => {
            apiService.getJrNumbers().then((result) => {
                const jrNumbers = result.data?.available_jr_numbers ?? [];
                setAllJrNumber(jrNumbers);
                setOptions(jrNumbers)
            })
        }
        getListJrNumber()
        setCountry()
    },[])

    const setCountry = () =>{
        setListCountry(["","Malaysia","Indonesia","Philippines","Thailand"])
    }

    function handleAssessmentChange(event) {
        const arrayAssessment = [];
        for (var i in assessmentList) {
            if (assessmentList[i].name === event.target.name) {
                assessmentList[i].checked = event.target.checked
            }
        }

        for (var k in assessmentList) {
            if (assessmentList[k].checked === true) {
                arrayAssessment.push(assessmentList[k].name)
            }
        }
        if (arrayAssessment.length > 0) {
            setFormError({
                ...formError,
                "assessment": formErrorObj
            })
        }
        setFormPayload({
            ...formPayload,
            "assessment": arrayAssessment
        })
        setAssessmentList([...assessmentList])
    }

    function handleChange(e) {
        const name = e.target.name;
        const val = e.target.value;

        setFormPayload({
            ...formPayload,
            [name]: val
        })

        setFormError({
            ...formError,
            [name]: formErrorObj
        })
        
    }

    function handleSelect(e, v) {
        const name = autoCompleteRef.current.getAttribute("name");
        const val = v;
        
        if ( name === "jrNumber" ) {
            if ( val && val !== "" ) {
                setOptions(allJrNumber)
            } else {
                setOptions(allJrNumber.slice(0, 10));
            }
        }

        setFormPayload({
            ...formPayload,
            [name]: val
        })

        setFormError({
            ...formError,
            [name]: formErrorObj
        })
    }

    useEffect(() => {
        const populateDetails = async () => {
            try {
                const jrNumber = formPayload.jrNumber;

                const eventData = await getJrNumberDetails(jrNumber);
                    
                delete eventData.jrNumber;
                
                setFormPayload({
                    ...formPayload,
                    ...eventData,
                });
            } catch(e) {
                console.error(e);
            }
        }  
        populateDetails();  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPayload.jrNumber])

    function handleDateChange(e, val) {
        let days
        let months
        let hours
        let minutes
        if (e !== null && !isNaN(e.$D) && !isNaN(e.$M) && !isNaN(e.$y) && !isNaN(e.$H) && !isNaN(e.$m)) {
            e.$D < 10 ? days = `0${e.$D}` : days = e.$D
            e.$M < 10 ? months = `0${e.$M + 1}` : months = e.$M + 1
            e.$H < 10 ? hours = `0${e.$H}` : hours = e.$H
            e.$m < 10 ? minutes = `0${e.$m}` : minutes = e.$m

            setFormPayload({
                ...formPayload,
                "dateTime": `${days}-${months}-${e.$y} ${hours}${minutes}`,
                "date":`${e.$y}-${months}-${days}`
            })
            setFormError({
                ...formError,
                "dateTime": formErrorObj
            })
        } else {
            setFormPayload({
                ...formPayload,
                "date": ""
            })
        }
    }

    function formValidator() {
        let formValid = true
        let nameErr = formErrorObj
        let jrNumberErr = formErrorObj
        let companyCountryErr = formErrorObj
        let aocErr = formErrorObj
        let aocCompanyErr = formErrorObj
        let venueErr = formErrorObj
        let dateErr = formErrorObj
        let timeErr = formErrorObj
        let assessmentErr = formErrorObj

        const today = new Date()
        const [year, month, day] = formPayload.date.split('-').map(Number)
        const convertDate = new Date(year, month, day)
        const stamp = convertDate.getTime()

        if (formPayload.aoc_company.length === 0 || formPayload.aoc_company === undefined) {
            aocCompanyErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (formPayload.aoc.length === 0 || formPayload.aoc === undefined) {
            aocErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (formPayload.name.length === 0 || formPayload.name === undefined) {
            nameErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (formPayload.jrNumber.length === 0 || formPayload.jrNumber === undefined) {
            jrNumberErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (formPayload.companyCountry.length === 0 || formPayload.companyCountry === undefined) {
            companyCountryErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (formPayload.venue.length === 0 || formPayload.venue === undefined) {
            venueErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (formPayload.dateTime.length === 0 || formPayload.dateTime === undefined) {
            dateErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if (formPayload.assessment.length === 0 || formPayload.assessment === undefined) {
            assessmentErr = { error: true, msg: 'Require' }
            formValid = false
        }
        if(stamp < today) {
            dateErr = { error: true, msg: 'Date has already passed' }
            formValid = false
        }

        setFormError({
            name: nameErr,
            jrNumber: jrNumberErr,
            companyCountry: companyCountryErr,
            aoc_company: aocCompanyErr,
            aoc: aocErr,
            venue: venueErr,
            dateTime: dateErr,
            time: timeErr,
            assessment: assessmentErr
        })

        return formValid
    }

    const [options,setOptions] = useState([]);

    function handleClose() {
        handleCloseDialog(false);
    }

    const [isAdding, setIsAdding] = useState(false);
    
    useEffect(() => {
        
        onUpdate({
            isAdding
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdding]) 

    async function handleSubmit(e) {
        e.preventDefault()

        if ( isAdding ) {
            return;
        }
        
        const isFormValid = formValidator()
        const uniqueJR = allEvent.find(obj => obj.jr_number === formPayload.jrNumber && obj.event_status !== 'Cancelled')

        if (uniqueJR !== undefined) {
            setErrorSave({
                ...errorSave,
                "msg": 'JR number already exist'
            })
            return
        }

        if (isFormValid === true) {
            const today = new Date()
            const year = today.getFullYear()
            const month = today.getMonth() + 1
            const day = today.getDate()
            const hours = today.getHours();
            const minutes = today.getMinutes();
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formatMonth = month.toString().padStart(2, '0')
            const formatDay = day.toString().padStart(2, '0')

            const form = {
                aoc: formPayload.aoc,
                event_aoc: formPayload.aoc_company,
                event_assessment_module: formPayload.assessment,
                event_date_time:formPayload.dateTime,
                event_date: formPayload.date,
                event_name: formPayload.name,
                event_venue: formPayload.venue,
                jr_number: formPayload.jrNumber,
                hiring_country: formPayload.companyCountry,
                posting_date: `${formatDay}-${formatMonth}-${year} ${formattedHours}:${formattedMinutes}`
            }

            setIsAdding(true);

            await addEvent(form);

            setIsAdding(false);

            handleCloseDialog(false);
            window.location.reload();
        }

    }
    return (
        <Box sx={{ position: 'relative' }}>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ p: 2, backgroundColor: "#E9798E" }}><Typography fontSize={'20px'} lineHeight={'22px'}  color="#FFFFFF">ADD NEW EVENT</Typography></DialogTitle>
                <DialogContent sx={{ border: 1, borderColor: "#EBEBEB", m: 1, }}>
                    <form autoComplete="off">
                        <Box display="flex" flexDirection="row" width="100%">
                            <Box sx={{flex: 2}}>
                                <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                    <TextField required size="small" id="name" name="name" label="Event Name" value={formPayload?.name ?? ""} onChange={handleChange} error={formError.name.error} helpertext={formError.name.msg} />
                                </FormControl>
                                <FormControl variant="standard"  sx={{ width: "100%", p:1 }}>
                                    <TextField
                                        required
                                        size="small"
                                        id="venue"
                                        name="venue"
                                        label="Event Location"
                                        onChange={handleChange}
                                        value={formPayload?.venue ?? ""}
                                        error={formError.venue.error} helpertext={formError.venue.msg}
                                    />
                                </FormControl>
                                <FormControl variant="standard" sx={{ width: "100%", p:1 }}>
                                    <TextField required size="small" id="aoc_company" name="aoc_company" value={formPayload?.aoc_company ?? ""}  label="Company Name" onChange={handleChange} error={formError.aoc_company.error} helpertext={formError.aoc_company.msg} />
                                </FormControl>
                                <TextField sx={{ display: 'none' }} type="hidden" hidden value={formPayload?.aoc ?? ""} />
                            </Box>
                            <Box sx={{flex: 1}}>
                                <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                    <Autocomplete
                                        ref={autoCompleteRef}
                                        key="jrNumber"
                                        id="jr-number"
                                        name="jrNumber"
                                        value={formPayload?.jrNumber ?? ""}
                                        options={options}
                                        limitTags={10}
                                        multiple={false}
                                        getOptionLabel={(option) => option ?? ""}
                                        onSelect={handleChange}
                                        onChange={handleSelect}
                                        error={formError.jrNumber.error === true ? "true" : undefined} helpertext={formError.jrNumber.msg}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                autoComplete="off"
                                                id="jr-number"
                                                name="jrNumber"
                                                label="JR Number"
                                                size="small"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl variant="standard" sx={{ p:1, width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker disablePast ampm={false} onChange={handleDateChange} className="date" label="Event Date Time"
                                            slotProps={{
                                                textField: {
                                                    helpertext: formError.dateTime.msg,
                                                    size: "small"
                                                },
                                            }}
                                            sx={{
                                                "& .MuiFormHelperText-root": {
                                                    color: "#c92d21 !important"
                                                }
                                            }} />
                                    </LocalizationProvider>
                                </FormControl>
                                <FormControl variant="standard" sx={{width: "100%", p:1}}>
                                    <TextField
                                        required
                                        onChange={handleChange}
                                        size="small"
                                        id="company-country"
                                        name="companyCountry"
                                        label="Hiring Country"
                                        value={formPayload?.companyCountry ?? ""}
                                        error={formError.companyCountry.error} helpertext={formError.companyCountry.msg}
                                        select
                                    >
                                        {listCountry.map((opt)=> (
                                            <MenuItem selected={formPayload?.companyCountry === opt} key={opt === "" ? "" : opt} value={opt === "" ? "" : opt}>{opt === "" ? "-" : opt}</MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                ml: 1,
                                mt: 1,
                            }}
                        >
                            <AssessmentRender list={assessmentList} handleChange={handleAssessmentChange} formError={formError.assessment} isDisabled={false} />
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions sx={{ m: 1 }}>
                    <Button onClick={handleClose}> Cancel </Button>
                    <Button disabled={isAdding} autoFocus type="submit" onClick={handleSubmit} variant="contained"> Submit </Button>
                </DialogActions>
                {isAdding && (
                    <Box sx={{ bgcolor: 'rgba(255,255,255,0.5)', position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Dialog>
            <ErrorEvent isDialogOpen={isErrDialogOpen}
                handleCloseDialog={() => {
                    setIsErrDialogOpen(false)
                    setErrorSave({
                        type: 'Saving',
                        msg: '',
                    })
                }} error={errorSave} />
        </Box>
    );
}

export default AddEvent;

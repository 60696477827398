import { db, storage } from "./firebase";
import { getAuth } from "firebase/auth";
import { 
    doc,
    addDoc,
    getDoc, 
    getDocs,
    setDoc, 
    updateDoc, 
    collection,
    where,
    query,
    serverTimestamp,
    collectionGroup
} from "firebase/firestore";

import {
    ref,
    uploadBytesResumable,
    getDownloadURL 
} from "firebase/storage"
import { getAge } from "../utils/isDate";
import { objectToCSV } from "../utils/objectsToCSV";
import { saveAs } from "file-saver";
import getShortMonthName from "../utils/getShortMonth";
import apiService from "./apiService";
import { decryptData } from "../utils/encryption";
import { getCandidateAssessmentResultCatwalk, getCandidateAssessmentResultDocumentCheck, getCandidateAssessmentResultMeasurement } from "./db_candidate";
import { getCandidateEventAssessmentEnglishExam, getCandidateEventAssessmentGroupDynamic, getCandidateEventAssessmentOneToOneInterview } from "./page";

const getEventById = async (eventId) => {
    const result = await apiService.getEvent({
        eventId
    });

    console.log('event:', result.data?.event );
    
    return result.data?.event ?? undefined;
}

const getEventAssessmentResult = async (eventId) => {
    let assessmentResult = {}
    const querySnapshot = await getDocs(collection(db, "events", eventId, "candidates"))
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        assessmentResult[doc.id] = {candidate_id: doc.id, event_id: eventId, result: {...doc.data()}}
    })
    //console.log(assessmentResult)
    return assessmentResult
}

const getAllEvent = async () => {
    // Get all documents in a collection
    let events = [];
    // const querySnapshot = await getDocs(collection(db, "events"));
    // querySnapshot.forEach((doc) => {
    //     // doc.data() is never undefined for query doc snapshots
    //     //console.log(doc.id, " => ", doc.data());
    //     events.push({id : doc.id,...doc.data()});
    // });
    // //console.log("Total candidate returned: " + querySnapshot.size);
    // //console.log(events);

    const result = await apiService.getEventList();

    events = result.data?.events ?? [];

    console.log(events);

    return events;
}

const getEventCandidateIds = async (eventId) => {
    // Get all document in a subcollection
    let eventCandidateIds = [];
    const querySnapshot = await getDocs(collection(db, "events", eventId,"candidates"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        //eventCandidateIds.push({"id" : doc.id,"data" : doc.data()});
        eventCandidateIds.push(doc.id);
    });
    //console.log(eventCandidateIds);
    return eventCandidateIds;
}

const addEvent = async (eventInfo) => {
    const auth = getAuth();
    const user = auth.currentUser;
    let createdBy = "";
    if(user != null){
        createdBy = user.email;
    }
    else{
        createdBy = "NA";
    }

    const result = await apiService.addEvent({
        eventInfo,
        createdBy
    });

    if ( result.data ) {
        const event = result.data?.event;
        if ( event ) {
            const eventId = event.id;

            await apiService.autoAddCandidates({ eventId });

            apiService.autoUpdateJobApplication({ eventId });
        }
    }
    
}

const updateEventById = async (eventId, eventInfo) => {
    // console.log(eventId);
    // console.log(eventData);
    const auth = getAuth();
    const user = auth.currentUser;
    let updatedBy = "";
    if(user != null){
        updatedBy = user.email;
    }
    else{
        updatedBy = "NA";
    }
    
    const result = await apiService.updateEvent({
        eventId,
        eventInfo,
        updatedBy
    });

    console.log(result);
    //console.log("Event (" + eventId + ") event_status updated to 'Cancelled'");
}

const addCandidateToEvent = async (candidateId, eventId) => {
    await setDoc(doc(db, "events", eventId, 'candidates', candidateId), {
        candidate_id: candidateId,
        updated_from: 'app',
        updated_at: serverTimestamp()
    });
    //console.log("Added candidate: " + candidateId + " to event: " + eventId);
}

const cancelEventById = async (eventId) => {
    await updateEventById(eventId, {
        event_status: "Cancelled"
    });
    //console.log("Event (" + eventId + ") event_status updated to 'Cancelled'");
}

const assignCandidateNumberToEventCandidate = async (candidateId, eventId) => {
    const eventDetails = await getEventById(eventId);
    const candidateNumberList = await getDocs(collection(db, "events", eventId, 'candidate_number'));
    const candidateNumberCount = candidateNumberList.size;
    
    const candidateNumber = eventDetails.event_code + (candidateNumberCount+1).toString().padStart(4, '0');
    let isInsertCandidateNumber = false;
    if(candidateNumberList.empty){
        isInsertCandidateNumber = true;
    }
    else{
        let hasCandidateNumber = false;
        candidateNumberList.forEach((candidate) => {
            if(candidate.data().candidate_id === candidateId.toString()){
                hasCandidateNumber = true;
            }
        });
        if(!hasCandidateNumber){
            isInsertCandidateNumber = true;
        }
    }
    
    if(isInsertCandidateNumber){
        //console.log({candidate_number: candidateNumber, candidate_id:candidateId})
        await setDoc(doc(db, "events",eventId,"candidate_number", candidateNumber), {
            candidate_id:candidateId,
            updated_from: 'app',
            updated_at: serverTimestamp()
        })
    }
}

const getAllCandidateNumberFromEvent = async (eventId) => {
    let candidateNumberList = [];
    const querySnapshot = await getDocs(collection(db, "events", eventId, "candidate_number"));
    querySnapshot.forEach((doc) => {
        candidateNumberList.push({candidate_number: doc.id, candidate_id: doc.data().candidate_id});
    });
    return candidateNumberList;
}

const getEventEmailTemplateById = async (eventId) => {
    let emailTemplate = {}
    const docRef = doc(db, "events", eventId, "email", "template");
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()){
        emailTemplate = {eventId: eventId, ...docSnap.data()}
    }
    //console.log(emailTemplate)
    return emailTemplate;
}

const setEventEmailTemplateById = async (eventId, emailTemplate) => {
    await setDoc(doc(db, "events", eventId, "email", "template"), emailTemplate);
}

const saveEventEmailImage = async ( eventId, file ) => {
    try {
        if ( !file ) {
            console.log("Invalid file")
            return "";
        }

        const fileName = file.name;
        const ext = fileName.split('.').pop();

        const storageRef = ref(storage, `/email/${eventId}/template.${ext}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        return downloadURL;
    } catch(error) {
        console.log(error);
        return "";
    }
}

const setAttachmentDetails = async (candidateId, eventId, attachments) => {
    const attachmentArray = Array.from(attachments);

    for (const attachment of attachmentArray) {
        await addDoc(collection(db, "events", eventId, "attachment", candidateId, "details"), {
            ...attachment,
            uploaded_at: serverTimestamp()
        });
    }
}

const getEventEmailTemplateFinalStatus = async (eventId) => {
    let emailTemplateFinalStatus = false
    const docRef = doc(db, "events", eventId)
    const docSnap = await getDoc(docRef)
    const eventInfo = docSnap.data()
    if(eventInfo.hasOwnProperty('is_email_template_final')){
        emailTemplateFinalStatus = eventInfo.is_email_template_final
    }
    //console.log(emailTemplateFinalStatus)
    return emailTemplateFinalStatus
}

const updateEventEmailTemplateFinalStatus = async (eventId, emailTemplateFinalStatus) => {
    await updateDoc(doc(db, "events", eventId), { is_email_template_final: emailTemplateFinalStatus })
}

const checkIfCandidateExistsInEvent = async ( eventId, candidateId ) => {
    const eventDoc = await getDoc( doc( db, "events", eventId,  "candidates", candidateId ) );

    return eventDoc.exists();
}

const setEmailSent = async (candidateId, eventId) => {
    const timestamp = new Date().getTime()
    await setDoc(doc(db, "events", eventId, 'email_sent', candidateId), {created_at: timestamp})
    //console.log("Added email sent for " + candidateId + ", event: " + eventId)
}

const getEmailSentList = async (eventId) => {
    let candidateIds = []
    const querySnapshot = await getDocs(collection(db, "events", eventId, "email_sent"));
    querySnapshot.forEach((doc) => {
        candidateIds.push(doc.id)
    })
    //console.log(candidateIds)
    return candidateIds
}

const getAllJrNumberDetails = async () => {
    let jrNumberDetails = {}
    const querySnapshot = await getDocs(collection(db, "event_jr_details"))
    querySnapshot.forEach((doc) => {
        jrNumberDetails[doc.id] = {...doc.data()}
    })
    //console.log(jrNumberDetails)
    return jrNumberDetails
}

const getJrNumberDetailsByJr = async (jrNumber) => {
    const jrDoc = await getDoc(doc(db, "event_jr_details", jrNumber))

    if ( jrDoc.exists() ) {
        return jrDoc.data();
    }  else {
        return {
            aoc: "",
            aoc_country: "",
            company: "",
            jr_number: "",
            location: "",
            title: "",
        }
    }
}

const getJrNumberDetails = async ( jrNumber ) => {
    let data = {
        name: "",
        jrNumber: "",
        companyCountry: "",
        aoc_company: "",
        aoc: "",
        venue: "",
    };

    try {
        if ( jrNumber ) {
            const eventDoc = await getDoc( doc(db, "event_jr_details", jrNumber ) );
    
            if (eventDoc.exists()) {
                const eventData = eventDoc.data();
    
                data = {
                    name: eventData?.title ?? "",
                    jrNumber: eventData?.jr_number ?? "",
                    companyCountry: eventData?.aoc_country ?? "",
                    aoc_company: [eventData?.aoc ?? "" , eventData?.company ?? ""].join(" "),
                    aoc: eventData?.aoc ?? "",
                    venue: eventData?.location ?? "",
                }
            }
        }

        return data;
    } catch(e) {
        console.error(e);
        return data;
    }
} 

/**
 * 
 * @param {*} eventId 
 * @returns 
 */
const getExAACount = async ( eventId ) => {
    let result = {
        special_registration_count: 0,
        special_registration_rate: 0,
        total_registration: 0,
    };
    const eventDoc = await getDoc(doc(db, "events", eventId));
    const jrNumber = eventDoc.data()?.jr_number; // Check if eventDoc is defined

    if (!jrNumber) {
        console.error('Invalid eventDoc or jr_number is undefined.');
        return [];
    }

    const q = query( collectionGroup(db, 'job_application' ), where("Job_Req_ID", "==", jrNumber) );

    const jobApplicationQS = await getDocs(q);

    const jobApplicationCount = jobApplicationQS.size;
    const hasJobApplications = jobApplicationCount > 0;

    if ( hasJobApplications ) {
        const  jobApplicationDocs = jobApplicationQS.docs;

        let _count = 0;
        for( const jobApplicationDoc of jobApplicationDocs ) {
            const jobApplicationData = jobApplicationDoc.data();

            if ( jobApplicationData?.Worked_AA_Before === "Yes" ) {
                _count += 1;
            }
        }

        result.special_registration_count = _count;
        result.total_registration = jobApplicationCount;
        result.special_registration_rate = _count > 0 
            ? ((_count / jobApplicationCount) * 100 )
            : 0;

    }

    console.log(result);

    return result;
}

/**
 * Gets all statics from candidates grouped by gender and grouped by age
 * @param {*} eventId 
 * @returns 
 */
const getBreakdownData = async ( eventId ) => {
    const eventDoc = await getDoc(doc(db, "events", eventId));

    const eventCandidatesQS = await getDocs(collection(db, "events", eventId, "candidates"));
    const eventCandidateIds = eventCandidatesQS.docs.map( d => d.id );

    const jrNumber = eventDoc.data()?.jr_number; // Check if eventDoc is defined

    if (!jrNumber) {
        console.error('Invalid eventDoc or jr_number is undefined.');
        return [];
    }

    const q = query(
        collection(db, "candidates"),
        where("jr_number", "array-contains", jrNumber)
    );

    const candidateQS = await getDocs(q);

    const hasCandidates = candidateQS.size > 0;

    let genders = [
        "Male",
        "Female",
        "Prefer not to declare"
    ]

    let genderBreakdown = [];
    for( const gender of genders ) {
        genderBreakdown.push({
            gender_breakdown: gender,
            assessed: 0,
            passed: 0,
            failed: 0,
            kiv: 0,
            blacklisted: 0,
        })
    }

    let ageRange = [
        { from: 18, to: 24 },
        { from: 25, to: 29 },
        { from: 30, to: 39 },
        { from: 40 } 
    ];

    let ageBreakdown = [];
    for( const range of ageRange ) {
        ageBreakdown.push({
            range: `${range.from}-${range?.to ?? "above"}`,
            assessed: 0,
            passed: 0,
            failed: 0,
            kiv: 0,
            blacklisted: 0,
        })
    }

    if ( hasCandidates ) {
        const candidateDocs = candidateQS.docs.filter( c => eventCandidateIds.includes( c.id ));
        
        // filter them by gender
        for ( const candidateDoc of candidateDocs ) {

            if ( candidateDoc.exists() ) {
                const candidateData = candidateDoc.data();

                const decDOB = await apiService.decrypt({
                    encryptedText: candidateData.dob
                });

                const age = getAge(decDOB.data);

                const isAssessed = candidateData?.assessment_status && 
                    candidateData?.assessment_status !== "" &&
                    candidateData?.assessment_status !== "Pending";

                const cGender = candidateData.gender ?? "Prefer not to declare";

                for( const gender of genders ) {
                    genderBreakdown = genderBreakdown.map((breakdown) => {
                        const isCurrent = breakdown.gender_breakdown === gender;
                        const isInGender = cGender === gender;

                        let _updatedGenderBreakdownData = {
                            ...breakdown
                        }

                        if ( isAssessed ) {
                            _updatedGenderBreakdownData.assessed += 1;
                        }
                
                        if ( candidateData.is_blacklist ) {
                            _updatedGenderBreakdownData.blacklisted += 1;
                        }
                
                        if ( candidateData.is_kiv || candidateData.assessment_status === "KIV" ) {
                            _updatedGenderBreakdownData.kiv += 1;
                        }
                
                        if ( candidateData.assessment_status === "Passed" ) {
                            _updatedGenderBreakdownData.passed += 1;
                        }
                
                        if ( candidateData.assessment_status === "Failed" ) {
                            _updatedGenderBreakdownData.failed += 1;
                        }


                        return isCurrent && isInGender
                            ? _updatedGenderBreakdownData
                            : breakdown
                     })
                }


                for( const range of ageRange ) {
                    const rangeKey = `${range.from}-${range?.to ?? "above"}`;
                    
                    ageBreakdown = ageBreakdown.map((breakdown) => {
                        const isCurrent = breakdown.range === rangeKey;
                        let _updatedAgeBreakdownData = {
                            ...breakdown
                        };
                        
                        if ( isAssessed ) {
                            _updatedAgeBreakdownData.assessed += 1;
                        }
                
                        if ( candidateData.is_blacklist ) {
                            _updatedAgeBreakdownData.blacklisted += 1;
                        }
                
                        if ( candidateData.is_kiv || candidateData.assessment_status === "KIV" ) {
                            _updatedAgeBreakdownData.kiv += 1;
                        }
                
                        if ( candidateData.assessment_status === "Passed" ) {
                            _updatedAgeBreakdownData.passed += 1;
                        }
                
                        if ( candidateData.assessment_status === "Failed" ) {
                            _updatedAgeBreakdownData.failed += 1;
                        }

                        const ageIsValid = age >= range.from && age <= (range?.to ?? 999 );
                        
                        return isCurrent && ageIsValid
                            ? _updatedAgeBreakdownData
                            : breakdown
                    })
                }
            }
            
        }
    }

    const data = {
        gender_breakdown: genderBreakdown,
        age_grouping: ageBreakdown,
    };

    console.log(data);

    return data;
}

/**
 * Get all blacklisted/kiv candidates details
 * @param {*} eventId 
 * @returns 
 */
const getBlacklistedAndKivCandidates = async (eventId) => {
    const eventDoc = await getDoc(doc(db, "events", eventId));
    const jrNumber = eventDoc.data()?.jr_number; // Check if eventDoc is defined

    if (!jrNumber) {
        console.error('Invalid eventDoc or jr_number is undefined.');
        return [];
    }

    const q1 = query(
        collection(db, "candidates"),
        where("is_blacklist", "==", true),
        where("jr_number", "array-contains", jrNumber)
    );
    const blackListedCandidateDocs = await getDocs(q1);

    const q2 = query(
        collection(db, "candidates"),
        where("is_kiv", "==", true),
        where("jr_number", "array-contains", jrNumber)
    );

    const q3 = query(
        collection(db, "candidates"),
        where("assessment_status", "==", "KIV"),
        where("jr_number", "array-contains", jrNumber)
    );

    const kivCandidateDocs = await getDocs(q2);
    const kivCandidateDocs2 = await getDocs(q3);

    let blacklisted = [];

    for( const candidate of blackListedCandidateDocs.docs ) {
        const candidateData = candidate.data();

        const reasonDocs = await getDocs(
            query(
                collection(db, "candidates", candidate.id, "blacklist_history"), 
                where("new_blacklist_value", "==", true)
            )
        );

        const reason = reasonDocs.docs[0]?.data()?.comment ?? "NA"

        const decDOB = await apiService.decrypt({
            encryptedText: candidateData.dob
        });

        const decNationalID = await apiService.decrypt({
            encryptedText: candidateData.national_id
        });

        blacklisted.push({
            ...candidateData,
            id: candidate.id,
            status: "Blacklisted",
            dob: decDOB.data,
            age: getAge(decDOB.data),
            national_id: decNationalID.data,
            reason: reason
        })
    }
 
    let iskivs = [];

    for( const candidate of [...kivCandidateDocs.docs, ...kivCandidateDocs2.docs] ) {
        const candidateData = candidate.data();

        const idExists = iskivs.some((item) => item.id === candidate.id);

        if ( !idExists ) {
            const assessmentDocs = await getDocs(
                collection( db, "candidates", candidate.id, "assessment", eventId, "result" )
            );
    
            let reasons = [];
    
            for( const assessment of assessmentDocs.docs ) {
                const assessmentData = assessment.data();
                reasons.push(assessmentData?.overall_score?.comment ?? assessmentData?.overall_comment ?? assessmentData?.interviewerinfo?.comment ?? "");
            }

            const decDOB = await apiService.decrypt({
                encryptedText: candidateData.dob
            });
    
            const decNationalID = await apiService.decrypt({
                encryptedText: candidateData.national_id
            });
    
            iskivs.push({
                ...candidateData,
                id: candidate.id,
                status: "KIV",
                dob: decDOB.data,
                age: getAge(decDOB.data),
                national_id: decNationalID.data,
                reason: reasons.join(" ")
            })
        }
    }

    console.log('blacklisted', blacklisted);
    console.log('kiv', iskivs)
    return {
        blacklisted,
        iskivs
    };
}

/**
 * Get show up rates per event
 * @param {string} eventId id of the event
 * @returns {object} show up rates
 */
const getDashboardEventData = async (eventId) => {
    let results = {
        showUpRates: 0,
        noShowUpRates: 0,
        passedRates: 0,
        failedRates: 0,
        kivRates: 0,
        inProgressRates: 0,
        metrics: {
            totalRegistered: 0,
            totalAttended: 0,
            totalNoShow: 0,
            totalPassed: 0,
            totalFailed: 0,
            totalKIV: 0,
            totalInProgress: 0,
        },
    };

    const {
        candidates = [],
        // eslint-disable-next-line no-unused-vars
        eventInfo = {}
    } = await getEventInfoById(eventId, {});

    const candidateCount = candidates.length;
    const hasCandidates = candidateCount > 0;

    if(hasCandidates) {
        let _attended = 0;
        let _noShow = 0;
        let _passed = 0;
        let _failed = 0;
        let _kiv = 0;
        let _inProgress = 0;

        for(const candidate of candidates){

            const isInProgress = candidate?.assessment_status &&
                candidate?.assessment_status === "In Progress";

            const isAssessed = (candidate?.assessment_status && 
                candidate?.assessment_status !== "" &&
                candidate?.assessment_status !== "Pending") || 
                candidate?.is_kiv === true ||
                candidate?.is_blacklist === true;

            const isPassed = candidate?.assessment_status &&
            candidate?.assessment_status === "Passed";

            const isFailed = (candidate?.assessment_status &&
                candidate?.assessment_status === "Failed") ||
                candidate?.is_blacklist === true;

            const isKiv = (candidate?.assessment_status &&
                candidate?.assessment_status === "KIV") ||
                candidate?.is_kiv === true ;

            if ( isPassed ) {
                _passed += 1;
            }
            if ( isFailed ) {
                _failed += 1;
            }
            if ( isKiv ) {
                _kiv += 1;
            }
            if (isAssessed) {
                _attended += 1;
            }
            if (isInProgress) {
                _inProgress += 1;
            }
        }
        _noShow = candidateCount - _attended;
        results.metrics.totalRegistered = candidateCount;
        results.metrics.totalAttended = _attended;
        results.metrics.totalNoShow = _noShow;
        results.metrics.totalPassed = _passed;
        results.metrics.totalFailed = _failed;
        results.metrics.totalKIV = _kiv;
        results.metrics.totalInProgress = _inProgress;
        results.showUpRates = _attended !== 0 ? (_attended / candidateCount) * 100 : 0;
        results.noShowUpRates = _noShow !== 0 ? (_noShow / candidateCount) * 100 : 0;
        results.inProgressRates = _inProgress !== 0 ? (_inProgress / candidateCount) * 100 : 0;
        results.passedRates = _passed !== 0 ? ( _passed / candidateCount ) * 100 : 0;
        results.failedRates = _failed !== 0 ? ( _failed / candidateCount ) * 100 : 0;
        results.kivRates = _kiv !== 0 ? ( _kiv / candidateCount ) * 100 : 0;
    }

    console.log('showuprates', results);

    return results;
}

/**
 * Get show up rates per event
 * @param {string} eventId id of the event
 * @returns {object} show up rates
 */
// const getShowUpRates = async (eventId) => {
//     let results = {
//         showUpRates: 0,
//         noShowUpRates: 0,
//         passedRates: 0,
//         failedRates: 0,
//         kivRates: 0,
//         inProgressRates: 0,
//         metrics: {
//             totalRegistered: 0,
//             totalAttended: 0,
//             totalNoShow: 0,
//             totalPassed: 0,
//             totalFailed: 0,
//             totalKIV: 0,
//             totalInProgress: 0,
//         },
//     };

//     try {
//         const eventDoc = await getDoc(doc(db, "events", eventId));
//         const jrNumber = eventDoc.data()?.jr_number; // Check if eventDoc is defined
//         const assessmentModules = eventDoc.data()?.event_assessment_module ?? [];
//         if (!jrNumber) {
//             console.error('Invalid eventDoc or jr_number is undefined.');
//             return [];
//         }

//         const q = query(
//             collection(db, "candidates"),
//             where("jr_number", "array-contains", jrNumber)
//         );

//         const eventCandidatesQS = await getDocs( collection(db, "events", eventId, "candidates" ) );
//         const eventCandidateIds = eventCandidatesQS.docs.map( d  => d.id );
        
//         const candidateQs = await getDocs(q);
        
//         const candidateDocs = candidateQs.docs.filter( c => eventCandidateIds.includes(c.id) );

//         const candidateCount = candidateDocs.length;
//         const hasCandidates = candidateCount > 0;

//         if (hasCandidates) {
//             let _attended = 0;
//             let _noShow = 0;
//             let _passed = 0;
//             let _failed = 0;
//             let _kiv = 0;
//             let _inProgress = 0;

//             let _candidates = [];

//             for ( const eventCandidateDoc of eventCandidatesQS.docs ) {
//                 const eventCandidateDocData = eventCandidateDoc.data();
        
//                 const matchingEventCandidate = candidateDocs.find(
//                     (candidateDoc) => candidateDoc.id === eventCandidateDoc.id,
//                 );

//                 if ( matchingEventCandidate ) {
//                     const _assessments = {};
//                     for ( const module of assessmentModules ) {
//                         const key = _getAsessmentKey(module);
//                         _assessments[key] = eventCandidateDocData[key] ?? "Pending";
//                     }

//                     let _assessmentStatus = "Pending";

//                     const passed = Object.values(_assessments).every((v) => v === "Passed" );
//                     const failed = Object.values(_assessments).includes("Failed") === true;
//                     const kiv = Object.values(_assessments).includes("KIV") === true;

//                     if ( failed ) {
//                         _assessmentStatus = "Failed";
//                     } else if ( kiv ) {
//                         _assessmentStatus = "KIV";
//                     } else if ( passed ) {
//                         _assessmentStatus = "Passed";
//                     }

//                     _candidates.push({
//                         id: eventCandidateDoc.id,
//                         asessments: _assessments,
//                         ...matchingEventCandidate.data(),
//                         assessment_status: _assessmentStatus,
//                     });
//                 }
//             }

//             for ( const candidateData of _candidates ) {

//                 const isAssessed = (candidateData?.assessment_status && 
//                     candidateData?.assessment_status !== "" &&
//                     candidateData?.assessment_status !== "Pending") || 
//                     candidateData?.is_kiv === true ||
//                     candidateData?.is_blacklist === true;

//                 const isPassed = candidateData?.assessment_status &&
//                     candidateData?.assessment_status === "Passed";

//                 const isFailed = (candidateData?.assessment_status &&
//                     candidateData?.assessment_status === "Failed") ||
//                     candidateData?.is_blacklist === true;

//                 const isKiv = (candidateData?.assessment_status &&
//                     candidateData?.assessment_status === "KIV") ||
//                     candidateData?.is_kiv === true ;


//                 if ( isPassed ) {
//                     _passed += 1;
//                 }

//                 if ( isFailed ) {
//                     _failed += 1;
//                 }

//                 if ( isKiv ) {
//                     _kiv += 1;
//                 }

//                 if (isAssessed) {
//                     _attended += 1;
//                 }
//             }
//             _noShow = candidateCount - _attended;
//             _inProgress = candidateCount - ( _passed + _failed + _kiv )

//             results.metrics.totalRegistered = candidateCount;
//             results.metrics.totalAttended = _attended;
//             results.metrics.totalNoShow = _noShow;
//             results.metrics.totalPassed = _passed;
//             results.metrics.totalFailed = _failed;
//             results.metrics.totalKIV = _kiv;
//             results.metrics.totalInProgress = _inProgress;

//             results.showUpRates = _attended !== 0 ? (_attended / candidateCount) * 100 : 0;
//             results.noShowUpRates = _noShow !== 0 ? (_noShow / candidateCount) * 100 : 0;
//             results.inProgressRates = _inProgress !== 0 ? (_inProgress / candidateCount) * 100 : 0;
//             results.passedRates = _passed !== 0 ? ( _passed / candidateCount ) * 100 : 0;
//             results.failedRates = _failed !== 0 ? ( _failed / candidateCount ) * 100 : 0;
//             results.kivRates = _kiv !== 0 ? ( _kiv / candidateCount ) * 100 : 0;
//         }
//     } catch (error) {
//         console.error('getShowUpRates', error);
//     }

//     console.log('showuprates', results);

//     return results;
// }

/**
 * Gets keys from assessment modules
 * @param {*} key assessment module
 * @returns 
 */
const _getAsessmentKey = (key) => {
    const keyMapping = {
        "Measurements": "measurement",
        "Document Check": "document_check",
        "Catwalk": "catwalk",
        "English Examinations": "english_exam",
        "Group Dynamics": "group_dynamic",
        "1:1 Interview": "one_to_one_interview",
    };

    return keyMapping[key] || null;
};

/**
 * Get the grand failure rate of event
 * @param {*} eventId 
 * @returns 
 */
const getGrandFailureRate = async ( eventId ) => {
    let grandFailureRate = []
    try {
        const eventDoc = await getDoc(doc(db, "events", eventId));
        const assessmentModules = eventDoc.data()?.event_assessment_module ?? [];
        const jrNumber = eventDoc.data()?.jr_number;

        const assessmentQS = await getDocs(collection(db, "events", eventId, "candidates"));
        const candidateQS = await getDocs(
            query( collection( db, "candidates"), where("jr_number", "array-contains", jrNumber))
        );

        const candidates = candidateQS.size > 0 
            ? candidateQS.docs.map((d) => {
                return {
                    ...d.data(),
                    id: d.id
                }
            } )
            : [];

        const hasAssessments = assessmentQS.size > 0;

        if ( hasAssessments ) {
            const assessmentDocs = assessmentQS.docs;
            const assessmentDatas = assessmentDocs.map((d) => {
                const candidate = candidates.find((c) => c.id === d.id );

                if ( candidate ) {
                    const isBlacklisted = candidate?.is_blacklist ?? false;
                    const isKIV = (candidate?.assessment_status &&
                        candidate?.assessment_status === "KIV") ||
                        candidate?.is_kiv === true ;
                    
                    return {
                        ...d.data(),
                        assessment_status: candidate?.assessment_status ?? "Pending",
                        is_blacklist: isBlacklisted,
                        is_kiv: isKIV
                    }
                } else {
                    return d.data();
                }
            } );

            let assessed = assessmentDatas;
            assessed = assessed.filter(obj => obj.assessment_status !== "Pending");

            const countValuesByKey = (arr) => {
              const counts = {};
            
              arr.forEach(obj => {
                Object.keys(obj).forEach(key => {
                  if (!counts[key]) {
                    counts[key] = {};
                  }
                  const value = obj[key];
                  if (!counts[key][value]) {
                    counts[key][value] = 0;
                  }
                  counts[key][value]++;
                });
              });
            
              return counts;
            };
            
            const valueCounts = countValuesByKey(assessed);

            let count = assessed.length
            let ongoing = true
            for( const assessmentModule of assessmentModules ) {
                const key = _getAsessmentKey(assessmentModule)
                let passed = 0
                let rejected = 0
                if(valueCounts[key]){
                    if(valueCounts[key]["Passed"]){
                        passed = passed + valueCounts[key]["Passed"]
                    }
                    if(valueCounts[key]["Failed"]){
                        rejected = rejected + valueCounts[key]["Failed"]
                    }
                    if(valueCounts[key]["KIV"]) {
                        rejected = rejected + valueCounts[key]["KIV"]
                    }
                }

                grandFailureRate.push({
                    assessment: assessmentModule,
                    assessed: ongoing ? count : 0,
                    rejected: rejected,
                    rejected_rate: rejected > 0 ? ((rejected / count) * 100) : 0,
                    passed: passed,
                    passed_rate: passed > 0 ? ((passed / count) * 100) : 0,
                });
                if(passed === 0){
                    ongoing = false
                }
                count = count - rejected
            }
        }
    } catch(error) {
        console.error('getGrandFailureRate:', error);
    }

    console.log(grandFailureRate);

    return grandFailureRate;
}

/**
 * Export candidates into csv
 * @param {Array} data 
 * @returns {void} readable as csv
 */
const dataCleanupForCSV = (data) => {
    data.forEach(obj => {
        Object.keys(obj).forEach(keys => {
            if(typeof obj[keys] === 'string'){
                obj[keys] = obj[keys].replaceAll(",", ";")
            }
        })
    })
}

/**
 * Export candidates into csv
 * @param {*} candidates 
 * @returns {Promis<string>} readable as csv
 */
const convertEventCandidatesToCSVString =  async ( candidates, eventId ) => {

    let candidateList = [];
    for( const candidate of candidates ) {
        const id = await decryptData(candidate.national_id);
        const email = await decryptData(candidate.email);
        const phoneNumber = await decryptData(candidate.phone_number);
        const catwalk = await getCandidateAssessmentResultCatwalk(candidate.candidate_id, eventId);
        const docCheck = await getCandidateAssessmentResultDocumentCheck(candidate.candidate_id,eventId)
        const measurement = await getCandidateAssessmentResultMeasurement(candidate.candidate_id,eventId)
        const englishResult = await getCandidateEventAssessmentEnglishExam(candidate.candidate_id,eventId)
        const groupResult = await getCandidateEventAssessmentGroupDynamic(candidate.candidate_id,eventId)
        const oneToOne = await getCandidateEventAssessmentOneToOneInterview(candidate.candidate_id,eventId)

        const allResult = [
            docCheck ? docCheck.overall_score.status : "Pending", 
            measurement ? measurement.overall_score.status : "Pending",
            catwalk ? catwalk.overall_score : "Pending",
            oneToOne ? oneToOne.overall_score.status : "Pending",
            groupResult ? groupResult.status : "Pending",
            englishResult ? englishResult.status : "Pending"
        ]

        let result = ""

        if(allResult.length > 0){
            if (allResult.includes("Failed")) {
                result = "Failed"
            } else if (allResult.includes("KIV")) {
                result = "KIV"
            } else if (allResult.includes("Pending")) {
                result = "Pending"
            } else {
                result = "Passed"
            }
        }

        candidateList.push({
            ...candidate,
            national_id: id,
            email,
            phone_number: phoneNumber,
            docCheckResult: docCheck ? docCheck.overall_score.status : "",
            measurementResult: measurement ? measurement.overall_score.status : "",
            catwalkResult: catwalk ? catwalk.overall_score : "",
            oneToOneResult: oneToOne ? oneToOne.overall_score.status : "",
            groupResult: groupResult ? groupResult.status : "",
            englishResult: englishResult ? englishResult.status : "",
            finalResult: result
        })
    }

    dataCleanupForCSV(candidateList)

    let csv = objectToCSV(
        [
            { label: "CANDIDATE #", key: "candidate_number" },
            { label: "FIRST NAME", key: "first_name" },
            { label: "LAST NAME", key: "last_name" },
            { label: "NATIONALITY", key: "nationality" },
            { label: "NATIONAL ID/PASSPORT", key: "national_id" },
            { label: "CONTACT NUMBER", key: "phone_number" },
            { label: "EMAIL", key: "email" },
            { label: "APPLICATION DATE", key: "application_date" },
            { label: "# OF EVENTS", key: "no_of_events" },
            { label: "GENDER", key: "gender" },
            { label: "CITY", key: "city" },
            { label: "COUNTRY", key: "country" },
            { label: "SOURCE", key: "source" },
            { label: "ASSESSMENT_STATUS", key: "assessment_status" },
            { label: "REGISTRATION_STATUS", key: "registration_status" },
            { label: "MEASUREMENT RESULT", key: "measurementResult" },
            { label: "DOCUMENT CHECK RESULT", key: "docCheckResult" },
            { label: "CATWALK RESULT", key: "catwalkResult" },
            { label: "ENGLISH EXAM RESULT", key: "englishResult" },
            { label: "GROUP DYNAMIC RESULT", key: "groupResult" },
            { label: "1-TO-1 RESULT", key: "oneToOneResult" },
            { label: "OVERALL RESULT", key: "finalResult" },
        ],
        candidateList,
    );

    const fileName = `candidates-export-${new Date().toISOString()}.csv`
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);

    return csv;
}

/**
 * Gets events filtered by months
 * @param {*} aoc
 * @returns 
 */
const getEventsFilterByMonths = async ( aoc = "" ) => {
    if ( !aoc ) {
        console.error("aoc is required");
        return [];
    }

    const q = query(
        collection(db, "events" ),
        where("aoc", "==", aoc)
    )

    const eventsQS = await getDocs(q);

    const hasEvents = eventsQS.size > 0;
    
    

    if ( hasEvents ) {
        const eventDocs = eventsQS.docs;

        let _filteredEvents = [];
        const years = [];

        for( const event of eventDocs ) {
            const eventData = event.data();
            const eventDate = eventData.event_date;

            const year = parseInt(eventDate.split("-")[0]);

            if ( !years.includes(year) ) {
                years.push(year)
            }
        }

        for( const year of years ) {
            for( let month = 1; month <= 12; month ++ ) {
                const yearMonth = `${year}_${month}`;
                const shortMonth = getShortMonthName(month);

                _filteredEvents.push({
                    year_month: yearMonth,
                    short_month: shortMonth,
                    month,
                    year,
                    events: []
                });

            }
        }

        for( const event of eventDocs ) {
            const eventData = event.data();

            const eventDate = eventData.event_date;
            const month = parseInt(eventDate.split("-")[1]);
            const year = parseInt(eventDate.split("-")[0]);

            _filteredEvents = _filteredEvents.filter((fe) => {
                const isCurrent = fe.year_month === `${year}_${month}`;

                if ( isCurrent ) {
                    const id = event.id
                    fe.events.push({id, ...eventData});
                }
                return fe;
            })
        }

        console.log(_filteredEvents);

        return _filteredEvents;
    } else {
        return [];
    }
}

/**
 * Search events using the jr number
 * @param {*} input 
 * @returns 
 */
const searchEventByJrNumber = async (input) => {


    let truncatedInput = "";

    truncatedInput = input
        .replace("J", "")
        .replace("R", "")
        .replace("JR", "")
        .replace("JR0", "")
        .replace("JR00", "");

    truncatedInput = truncatedInput
        .replace(/^0+/, '');

    if ( truncatedInput.length > 5 ) {
        const digits = input.replace(/\D/g, '');
        truncatedInput = `JR${digits.padStart(7, '0')}`
    } else {
        truncatedInput = `JR00${truncatedInput}`;
    }

    console.log('input:', truncatedInput);

    const q = query(
        collection(db, "events"),
        where("jr_number", ">=", truncatedInput),
        where("jr_number", "<=", truncatedInput + "\uf8ff")
    );

    const eventsQS = await getDocs(q);

    const hasEvents = eventsQS.size > 0;

    if (hasEvents) {
        const events = eventsQS.docs.map((eventDoc) => {
            const id = eventDoc.id;
            return { id, ...eventDoc.data() };
        });

        return events;
    } else {
        return [];
    }
};

/**
 * Search events by date
 * @param {string} dateString accepts dd/mm/yyyyy format 
 */
const searchEventByDate = async ( dateString ) => {
    if ( !dateString ) {
        return [];
    }

    const [ day, month, year ] = dateString.split("/");

    const q = query(
        collection(db, "events"),
        where("event_date", "==", `${year}-${month}-${day}` )
    );

    const eventsQS = await getDocs(q);

    const hasEvents = eventsQS.size > 0;

    if ( hasEvents ) {
        const events = eventsQS.docs.map((eventDoc) => eventDoc.id );

        return events;
    } else {
        return [];
    }
}

const _generateEventFileName = async ( prefix = "Dashboard", eventId ) => {
    if ( !eventId ) {
        return "";
    }

    try {
        const eventDoc = await getDoc(doc(db, "events", eventId));
        
        if ( eventDoc.exists() ) {
            const event = eventDoc.data();
            const eventCode = event.event_code;
            const eventDateArr = event.event_date.split("-");
            const eventDate = `${eventDateArr[2]}${eventDateArr[1]}${eventDateArr[0]}`;

            return `${prefix}-Event-${eventCode}_${eventDate}.csv`;
        } else {  
            return "";
        }
    } catch(err) {
        console.error('_getEventData', err);
        return "";
    }
}

/**
 * Export show up rates into CSV
 * @param {*} eventId 
 */
const exportShowUpRates = async (eventId) => {
    const sr = await getDashboardEventData(eventId);
    const eac = await getExAACount(eventId);

    let csv = "";

    csv += ["", "Count", "Percentage"].join(",") + "\r\n";
    csv += [
        "Total registration",
        sr.metrics.totalRegistered,
        100
    ].join(",") + "\r\n";

    csv += [
        "Total show up",
        sr.metrics.totalAttended,
        sr.showUpRates
    ].join(",") + "\r\n";

    csv += [
        "No show",
        sr.metrics.totalNoShow,
        sr.noShowUpRates
    ].join(",") + "\r\n";

    csv += [
        "Special registration",
        eac.special_registration_count,
        eac.special_registration_rate
    ].join(",") + "\r\n";

    csv += [
        "Successful candidates",
        sr.metrics.totalPassed,
        sr.passedRates,
    ].join(",") + "\r\n";

    csv += [
        "Failed candidates",
        sr.metrics.totalFailed,
        sr.failedRates,
    ].join(",") + "\r\n";

    csv += [
        "KIV",
        sr.metrics.totalKIV,
        sr.kivRates,
    ].join(",") + "\r\n";


    const fileName = await _generateEventFileName('Dashboard-Show-Up-Rate', eventId);
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);
}

/**
 * Export grand failure rates into CSV
 * @param {*} eventId 
 */
const exportGrandFailureRate = async (eventId) => {
    const rates = await getGrandFailureRate(eventId);

    let csv = objectToCSV(
        [
            { label: "Type of assessment", key: "assessment" },
            { label: "No. of candidates", key: "assessed" },
            { label: "No. of rejection", key: "rejected" },
            { label: "Rejection percentage", key: "rejected_rate" },
            { label: "No. of passed", key: "passed" },
            { label: "Passed percentage", key: "passed_rate" },
        ],
        rates,
    );
    
    const fileName = await _generateEventFileName('Dashboard-Grand-Failure-Rate', eventId);
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);
}

/**
 * Export gender breakdown data into CSV
 * @param {*} eventId 
 */
const exportGenderBreakdown = async ( eventId ) => {
    const breakdown = await getBreakdownData(eventId);
    const genderBreakdown = breakdown.gender_breakdown;

    let csv = objectToCSV(
        [
            { label: "Gender", key: "gender_breakdown" },
            { label: "Total assessed", key: "assessed" },
            { label: "Passed", key: "passed" },
            { label: "Failed", key: "failed" },
            { label: "KIV", key: "kiv" },
            { label: "Blacklisted", key: "blacklisted" },
        ],
        genderBreakdown,
    );

    const fileName = await _generateEventFileName('Dashboard-Gender-Breakdown', eventId)
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);

}

/**
 * Export age grouping data into CSV
 * @param {*} eventId 
 */
const exportAgeGrouping = async ( eventId ) => {
    const breakdown = await getBreakdownData(eventId);
    const ageGrouping = breakdown.age_grouping;

    let csv = objectToCSV(
        [
            { label: "Age Group", key: "range" },
            { label: "Total assessed", key: "assessed" },
            { label: "Passed", key: "passed" },
            { label: "Failed", key: "failed" },
            { label: "KIV", key: "kiv" },
            { label: "Blacklisted", key: "blacklisted" },
        ],
        ageGrouping,
    );

    const fileName = await _generateEventFileName('Dashboard-Age-Grouping', eventId)
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);

}

/**
 * Export all dashboard data into one CSV
 * @param {*} eventId 
 */
const exportAllToCSV = async ( eventId ) => {
    const breakdown = await getBreakdownData(eventId);
    const genderBreakdown = breakdown.gender_breakdown;
    const ageGrouping = breakdown.age_grouping;
    const rates = await getGrandFailureRate(eventId);
    const sr = await getDashboardEventData(eventId);
    const eac = await getExAACount(eventId);

    let csv = "";

    // show up rates
    csv += "Show up rate\r\n";
    csv += ["", "Count", "Percentage"].join(",") + "\r\n";
    csv += [
        "Total registration",
        sr.metrics.totalRegistered,
        100
    ].join(",") + "\r\n";

    csv += [
        "Total show up",
        sr.metrics.totalAttended,
        sr.showUpRates
    ].join(",") + "\r\n";

    csv += [
        "No show",
        sr.metrics.totalNoShow,
        sr.noShowUpRates
    ].join(",") + "\r\n";

    csv += [
        "Special registration",
        eac.special_registration_count,
        eac.special_registration_rate
    ].join(",") + "\r\n";

    csv += [
        "Successful candidates",
        sr.metrics.totalPassed,
        sr.passedRates,
    ].join(",") + "\r\n";

    csv += [
        "Failed candidates",
        sr.metrics.totalFailed,
        sr.failedRates,
    ].join(",") + "\r\n";

    csv += [
        "KIV",
        sr.metrics.totalKIV,
        sr.kivRates,
    ].join(",") + "\r\n\r\n";
    // show up rates

    // grand failure rate
    csv +="Grand failure rate\r\n";
    csv += objectToCSV(
        [
            { label: "Type of assessment", key: "assessment" },
            { label: "No. of candidates", key: "assessed" },
            { label: "No. of rejection", key: "rejected" },
            { label: "Rejection percentage", key: "rejected_rate" },
            { label: "No. of passed", key: "passed" },
            { label: "Passed percentage", key: "passed_rate" },
        ],
        rates,
    ) + "\r\n\r\n";
    // grand failure rate
    
    // gender breakdown
    csv += "Gender breakdown\r\n"
    csv += objectToCSV(
        [
            { label: "Gender", key: "gender_breakdown" },
            { label: "Total assessed", key: "assessed" },
            { label: "Passed", key: "passed" },
            { label: "Failed", key: "failed" },
            { label: "KIV", key: "kiv" },
            { label: "Blacklisted", key: "blacklisted" },
        ],
        genderBreakdown,
    ) + "\r\n\r\n";
    // gender breakdown

    // age grouping
    csv += "Age grouping\r\n";
    csv += objectToCSV(
        [
            { label: "Age Group", key: "range" },
            { label: "Total assessed", key: "assessed" },
            { label: "Passed", key: "passed" },
            { label: "Failed", key: "failed" },
            { label: "KIV", key: "kiv" },
            { label: "Blacklisted", key: "blacklisted" },
        ],
        ageGrouping,
    ) + "\r\n\r\n";
    // age grouping

    const fileName = await _generateEventFileName('Dashboard', eventId)
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);
}

//
const getEventInfoById = async ( eventId, filter = {} ) => {
    
    const result = await apiService.getEventCandidateList({
        eventId,
        filter
    });

    console.log(result);

    const data = result.data;

    return data;
}

const convertReportsToCSV = ( reports ) => {
    let csv = objectToCSV(
        [
            { label: "Candidate Number", key: "candidateNumber" },
            { label: "Candidate ID", key: "candidateId" },
            { label: "JR Number", key: "jrNumber"},
            { label: "Job Application ID", key: "jobApplicationId" },
            { label: "Response", key: "message" },
            { label: "Actual Response", key: "actualResponse" }
        ],
        reports,
    );
    
    const fileName = `reports-${new Date().toISOString()}.csv`
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);
}

export {
    getEventById,
    getEventAssessmentResult,
    getAllEvent, 
    getEventCandidateIds, 
    addEvent,
    updateEventById,
    addCandidateToEvent,
    cancelEventById,
    assignCandidateNumberToEventCandidate,
    getAllCandidateNumberFromEvent,
    getEventEmailTemplateById,
    setEventEmailTemplateById,
    saveEventEmailImage,
    setAttachmentDetails,
    getEventEmailTemplateFinalStatus,
    updateEventEmailTemplateFinalStatus,
    checkIfCandidateExistsInEvent,
    setEmailSent,
    getEmailSentList,
    getAllJrNumberDetails,
    getJrNumberDetails,
    getJrNumberDetailsByJr,
    getDashboardEventData,
    convertEventCandidatesToCSVString,
    searchEventByJrNumber,
    getEventsFilterByMonths,
    searchEventByDate,
    getExAACount,
    getBreakdownData,
    getBlacklistedAndKivCandidates,
    getGrandFailureRate,
    exportShowUpRates,
    exportGrandFailureRate,
    exportGenderBreakdown,
    exportAgeGrouping,
    exportAllToCSV,
    getEventInfoById,
    convertReportsToCSV
}